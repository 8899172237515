<template>
  <div>
    <div class="row mt-5">
      <div class="col-md-12 col-lg-4 p-0">
        <img
          class="d-block img-fluid w-100 h-auto"
          src="@/assets/images/about.png"
          :alt="$t('menu.about')"
        />
      </div>
      <div class="col-md-12 col-lg-8 text-xl pl-4 pr-lg-4">
        <p>
          MyCare nasce dall’esperienza ultradecennale dei suoi soci nell’ambito
          dell’assistenza domiciliare con l’obiettivo di innovare il settore e
          introdurre soluzioni digitali che possano affiancare il necessario
          rapporto diretto tra Famiglia, lavoratore e intermediario/consulente
          per i servizi di assistenza.
        </p>
        <p>
          Il Progetto di MyCare prevede l’offerta sul mercato di un servizio,
          denominato RSA Domiciliare, nel quale la famiglia trova tutte le
          soluzioni ai bisogni di aiuto, assistenza e monitoraggio, soprattutto
          delle persone anziane e malate. Così alla figura tipica della
          «badante» vengono aggiunti servizi sanitari a domicilio, telemedicina
          e teleconsulto, tecnologia assistiva appositamente progettata per la
          cura, il monitoraggio e la sicurezza degli anziani e infine sono
          realizzate piattaforme digitali per offrire in modo snello e di facile
          gestione servizi di breve e media durata e soluzioni ad hoc per le
          famiglie.
        </p>
        <p>
          L’Ecosistema MyCare si basa su una capillare rete di partners che
          permette di selezionare e scegliere le migliori competenze oggi
          disponibili sul mercato e le soluzioni più adeguate per offrire alle
          famiglie Serenità e Sicurezza in merito alla assistenza dei propri
          cari.
        </p>
      </div>
    </div>
    <div class="row mx-lg-5 mx-2 mb-3">
      <div class="col-lg-12">
        <hr class="bg-primary" />
        <div class="row">
          <div class="col-lg-2 col-md-12">
            I NOSTRI PARTNER
          </div>
          <div class="col-lg-4 col-md-12 text-right">
            <img
              src="@/assets/images/teleserenita.png"
              class="partner"
              alt="Teleserenit&agrave;"
            />
          </div>
          <div class="col-lg-6 col-md-12">
            <p>
              Teleserenit&agrave; è presente con oltre 50 Agenzie in Italia e
              dal 1995 si prende cura degli anziani offrendo servizi di
              assistenza domiciliare ad ore e in convivenza e di assistenza
              ospedaliera.
            </p>
            <p>
              Le agenzie sono presidiate da consulenti che affiancano la
              famiglia dall’analisi del bisogno alla gestione dei servizi di
              assistenza.
            </p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-2 col-md-12"></div>
          <div class="col-lg-4 col-md-12 text-right">
            <p>
              Serenit&agrave; è una Agenzia per il Lavoro autorizzata dal
              Ministero per la ricerca, selezione e somministrazione di
              personale. In particolare opera nell’ambito dei servizi
              domiciliari attraverso la ricerca, selezione e somministrazione di
              colf, babysitter e assistenti domiciliari.
            </p>
          </div>
          <div class="col-lg-6 col-md-12">
            <img
              class="partner"
              src="@/assets/images/serenita.png"
              alt="Serenit&agrave;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";

export default {
  name: "About",
  metaInfo: {
    titleTemplate: "%s" + i18n.t("menu.about"),
  },
};
</script>

<style scoped>
.text-xl {
  font-size: 1.3rem;
}
.partner {
  max-height: 150px;
  max-width: 100%;
}
</style>
