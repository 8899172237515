var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mt-5" }, [
      _c("div", { staticClass: "col-md-12 col-lg-4 p-0" }, [
        _c("img", {
          staticClass: "d-block img-fluid w-100 h-auto",
          attrs: {
            src: require("@/assets/images/about.png"),
            alt: _vm.$t("menu.about")
          }
        })
      ]),
      _vm._m(0)
    ]),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-md-12 col-lg-8 text-xl pl-4 pr-lg-4" },
      [
        _c("p", [
          _vm._v(
            " MyCare nasce dall’esperienza ultradecennale dei suoi soci nell’ambito dell’assistenza domiciliare con l’obiettivo di innovare il settore e introdurre soluzioni digitali che possano affiancare il necessario rapporto diretto tra Famiglia, lavoratore e intermediario/consulente per i servizi di assistenza. "
          )
        ]),
        _c("p", [
          _vm._v(
            " Il Progetto di MyCare prevede l’offerta sul mercato di un servizio, denominato RSA Domiciliare, nel quale la famiglia trova tutte le soluzioni ai bisogni di aiuto, assistenza e monitoraggio, soprattutto delle persone anziane e malate. Così alla figura tipica della «badante» vengono aggiunti servizi sanitari a domicilio, telemedicina e teleconsulto, tecnologia assistiva appositamente progettata per la cura, il monitoraggio e la sicurezza degli anziani e infine sono realizzate piattaforme digitali per offrire in modo snello e di facile gestione servizi di breve e media durata e soluzioni ad hoc per le famiglie. "
          )
        ]),
        _c("p", [
          _vm._v(
            " L’Ecosistema MyCare si basa su una capillare rete di partners che permette di selezionare e scegliere le migliori competenze oggi disponibili sul mercato e le soluzioni più adeguate per offrire alle famiglie Serenità e Sicurezza in merito alla assistenza dei propri cari. "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mx-lg-5 mx-2 mb-3" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("hr", { staticClass: "bg-primary" }),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-2 col-md-12" }, [
            _vm._v(" I NOSTRI PARTNER ")
          ]),
          _c("div", { staticClass: "col-lg-4 col-md-12 text-right" }, [
            _c("img", {
              staticClass: "partner",
              attrs: {
                src: require("@/assets/images/teleserenita.png"),
                alt: "Teleserenit&agrave;"
              }
            })
          ]),
          _c("div", { staticClass: "col-lg-6 col-md-12" }, [
            _c("p", [
              _vm._v(
                " Teleserenità è presente con oltre 50 Agenzie in Italia e dal 1995 si prende cura degli anziani offrendo servizi di assistenza domiciliare ad ore e in convivenza e di assistenza ospedaliera. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Le agenzie sono presidiate da consulenti che affiancano la famiglia dall’analisi del bisogno alla gestione dei servizi di assistenza. "
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "row mt-3" }, [
          _c("div", { staticClass: "col-lg-2 col-md-12" }),
          _c("div", { staticClass: "col-lg-4 col-md-12 text-right" }, [
            _c("p", [
              _vm._v(
                " Serenità è una Agenzia per il Lavoro autorizzata dal Ministero per la ricerca, selezione e somministrazione di personale. In particolare opera nell’ambito dei servizi domiciliari attraverso la ricerca, selezione e somministrazione di colf, babysitter e assistenti domiciliari. "
              )
            ])
          ]),
          _c("div", { staticClass: "col-lg-6 col-md-12" }, [
            _c("img", {
              staticClass: "partner",
              attrs: {
                src: require("@/assets/images/serenita.png"),
                alt: "Serenit&agrave;"
              }
            })
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }